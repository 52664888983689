import React from "react";
import { observer } from "mobx-react";
import { Box, Button, Checkbox } from "@material-ui/core";
import { css } from "glamor";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MiniPlayer from "../lib/components/MiniPlayer";
import Player from "../lib/audiox/models/Player";
import { defaultStyles } from "../libs/styles";
import { downloadBlob } from "../lib/audiox/models/utils";

interface MemoPlayerType {
  player: Player;
}
const MemoPlayer: React.FC<MemoPlayerType> = observer(
  ({ player }) => {
    const [reverb, setReverb] = React.useState(false);
    return (
      <Box>
        <MiniPlayer
          onToggle={() => {
            player.togglePlayPause(reverb);
          }}
          onSetVolume={(value) => {
            player.setVolume(value);
          }}
          volume={player.volumeValue}
          showVolumeControls={true}
          isPlaying={player.isPlaying}
          duration={player.duration}
          currentTime={player.currentTime}
          progress={player.currentProgress}
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex">
            <Checkbox
              checked={reverb}
              onClick={() => {
                setReverb(!reverb);
              }}
              disabled={player.isPlaying}
            />
            <span
              onClick={() => {
                setReverb(!reverb);
              }}
              {...css(defaultStyles.font.body, {
                fontSize: "10px",
                cursor: "pointer",
              })}
            >
              Add reverb
            </span>
          </Box>
          <button
            {...css(defaultStyles.button.primary)}
            onClick={async () => {
              if (reverb) {
                // Decode audio
                if (player.audioContext) {
                  // const audioBuffer = player.audioContext.createBuffer(
                  //   2,
                  //   22050, // 22050 frames
                  //   44100, // 44100Hz
                  // );
                  // const destinationArray = new Float32Array();
                  // audioBuffer.copyFromChannel(destinationArray, 1, 0);
                  player.audioContext
                    .decodeAudioData(player.source)
                    .then(function (buffer) {
                      // Process Audio
                      console.log(buffer);
                    });
                }
              } else {
                if (player.blob) {
                  downloadBlob(player.blob, "voicememo.webm");
                }
              }
            }}
          >
            <CloudDownloadIcon />
            <span>Download recording</span>
          </button>
        </Box>
      </Box>
    );
  },
);
export default MemoPlayer;
