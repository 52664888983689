import { Box, Grid, Slider } from "@material-ui/core";
import { css } from "glamor";
import { observer } from "mobx-react";
import React from "react";
import MediaButton from "./MediaButton";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";

const offset = 10;
const playerStyles = {
  display: "flex",
  alignItems: "center",
};

const timestampStyle = {
  color: "#666",
  fontSize: "13px",
  marginLeft: "10px",
};

interface MiniPlayerType {
  currentTime: any;
  duration: any;
  height?: number;
  isPlaying: boolean;
  onSetVolume?: (value: number) => void;
  onToggle: () => void;
  progress: number;
  showTimecode?: boolean;
  showVolumeControls?: boolean;
  strokeWidth?: number; // width of the progress
  volume: number;
  width?: number;
}

const MiniPlayer: React.FC<MiniPlayerType> = observer(
  ({
    currentTime,
    duration,
    height = 38,
    isPlaying,
    onSetVolume,
    onToggle,
    progress,
    showTimecode = true,
    showVolumeControls = false,
    strokeWidth = 4,
    volume,
    width = 38,
  }) => {
    const w = width + offset;
    const h = height + offset;
    const radius = w / 2 - strokeWidth;
    const circumference = radius * 2 * Math.PI;
    const progressOffset =
      circumference - (progress / 100) * circumference;

    return (
      <div {...css(playerStyles)}>
        <div style={{ position: "absolute", marginLeft: offset / 2 }}>
          <MediaButton
            isPlaying={isPlaying}
            onToggle={onToggle}
            width={width}
            height={height}
          />
        </div>
        <svg width={w} height={h}>
          <circle
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: `${progressOffset}`,
            }}
            stroke="white"
            strokeWidth={strokeWidth}
            fill="transparent"
            r={radius}
            cx={w / 2}
            cy={h / 2}
          />
        </svg>
        {showTimecode && (
          <div {...css(timestampStyle)}>
            {convertSecondsToHHMMSS(currentTime)} /
            {convertSecondsToHHMMSS(duration)}
          </div>
        )}
        {showVolumeControls && (
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="24px"
          >
            <Box display="flex" alignItems="center" width="200px">
              <Grid container spacing={2}>
                <Grid item>
                  <VolumeDown />
                </Grid>
                <Grid item xs>
                  <Slider
                    min={0}
                    max={1}
                    step={0.01}
                    value={volume}
                    onChange={(
                      event: any,
                      newValue: number | number[],
                    ) => {
                      console.log({ newValue });
                      onSetVolume && onSetVolume(newValue as number);
                    }}
                    aria-labelledby="continuous-slider"
                  />
                </Grid>
                <Grid item>
                  <VolumeUp />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </div>
    );
  },
);
export default MiniPlayer;

export const convertSecondsToHHMMSS = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};
