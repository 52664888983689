import React from "react";
import { css } from "glamor";
import { colors } from "./styles";

const borderWidth = "2px";
const borderRadius = "50%";
const buttonStyles = {
  borderRadius: borderRadius,
  outline: "none",
  boxShadow: "0px 7px 10px 0px #171717",
  background: "linear-gradient(to bottom, #4a4a4a 5%, #292929 100%)",
  backgroundColor: "#4a4a4a",
  border: `${borderWidth}  solid black`,
  display: "inline-block",
  cursor: "pointer",
  color: "#777777",
  fontFamily: "Verdana",
  fontSize: "15px",
  fontWeight: "bold",
  margin: 0,
  padding: 0,
  textDecoration: "none",
  textShadow: "0px 1px 0px #000000",
  position: "relative",
  ":hover": {
    background: "linear-gradient(to bottom, #292929 5%, #4a4a4a 100%)",
    backgroundColor: "#292929",
  },
};

const buttonWrapperStyle = {
  margin: 0,
  padding: 0,
  boxShadow: "inset 0px 3px 6px 0px #565656",
  width: "100%",
  height: "100%",
  borderRadius,
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const playStyle = {
  width: "0",
  height: "0",
  marginLeft: "2px",
  borderTop: "5px solid transparent",
  borderBottom: "5px solid transparent",
  borderLeft: `10px solid ${colors.secondary}`,
};

const stopStyle = {
  width: "8px",
  height: "8px",
  backgroundColor: "#151010",
};

const recordStyle = {
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: "red",
};

interface PropType {
  isPlaying: boolean;
  onToggle: () => void;
  width?: number;
  height?: number;
  record?: boolean;
}
const MediaButton: React.FC<PropType> = ({
  isPlaying,
  onToggle,
  width = 34,
  height = 34,
  record = false,
}) => {
  return (
    <button
      {...css(buttonStyles, { width: `${width}px`, height: `${height}px` })}
      onClick={() => onToggle()}
    >
      <div {...css(buttonWrapperStyle)}>
        {isPlaying && <div {...css(stopStyle)} />}
        {!isPlaying && !record && <div {...css(playStyle)} />}
        {!isPlaying && record && <div {...css(recordStyle)} />}
      </div>
    </button>
  );
};
export default MediaButton;
