import { action, computed, makeObservable, observable } from "mobx";
import { Recorder } from "./models/Recorder";
import Microphone from "./models/Microphone";
import Visualizer from "./models/Visualizer";
import AudioSource from "./models/AudioSource";
import Player from "./models/Player";

export default class AudioX {
  recorder: Recorder;
  microphone: Microphone;
  audioSource: AudioSource;
  recordedAudioBlob: Blob | undefined;
  recordedAudioUrl: string;
  visualizer: Visualizer;
  player: Player;
  voiceMemos: any[];

  constructor() {
    this.recorder = new Recorder(this.onRecordingComplete);
    this.microphone = new Microphone();
    this.audioSource = new AudioSource();
    this.visualizer = new Visualizer();
    this.player = new Player();
    this.voiceMemos = [];
    if (typeof window != "undefined") {
      this.recordedAudioBlob = new Blob();
    } else {
      this.recordedAudioBlob = undefined;
    }
    this.recordedAudioUrl = "";

    makeObservable(this, {
      visualizer: observable,
      recorder: observable,
      audioSource: observable,
      recordedAudioBlob: observable,
      recordedAudioUrl: observable,
      onRecordingComplete: action,
      setRecordedAudioUrl: action,
      startRecording: action,
      stopRecording: action,
      currentVoiceMemos: computed,
      voiceMemos: observable,
    });
  }

  get currentVoiceMemos() {
    return this.voiceMemos;
  }

  get audioUrl() {
    return this.recordedAudioUrl;
  }

  setRecordedAudioUrl = (url: string) => {
    this.recordedAudioUrl = url;
  };

  onRecordingComplete = async (audio: Blob) => {
    this.recordedAudioBlob = audio;
    const url = URL.createObjectURL(audio);
    this.setRecordedAudioUrl(url);
    const player = new Player();
    player.setBlob(audio);
    player.loadMedia(url);
    this.voiceMemos = [...this.voiceMemos, { url, player }];
  };

  startRecording = async (showLiveFeedback?: boolean) => {
    const started = this.microphone.startStream();
    if (started && this.microphone.streamingStream) {
      if (this.microphone.isReady) {
        await this.audioSource.initWithStream(
          this.microphone.streamingStream,
          showLiveFeedback,
        );

        this.recorder.init(this.microphone.streamingStream);
        this.recorder.start();
        const canvas = document.getElementById(
          "visualizer",
        ) as HTMLCanvasElement;
        if (canvas) {
          this.visualizer.setCanvas(canvas);
          if (this.audioSource.analyzer) {
            this.visualizer.visualizeFreqBar(
              this.audioSource.analyzer,
            );
          }
        }
      }
    }
  };

  stopRecording = () => {
    this.recorder.stop();
    this.microphone.pauseStreaming();
    this.visualizer.turnOff();
  };
}
