// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { action, computed, makeObservable, observable } from "mobx";
import AudioX from "../lib/audiox/AudioX";
export enum PopupOption {
  recordingBooth = "recordingBooth",
}
export default class AppStore {
  viewPopupId?: string;
  recordingBooth: AudioX;

  constructor() {
    this.recordingBooth = new AudioX();
    this.viewPopupId = undefined;

    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDHBDHo1H4zy6nY5lVnI4PdVvXN4r878rk",
      authDomain: "stephanimoroni-59d32.firebaseapp.com",
      projectId: "stephanimoroni-59d32",
      storageBucket: "stephanimoroni-59d32.appspot.com",
      messagingSenderId: "685054616064",
      appId: "1:685054616064:web:81a1ba64ee40a125de2ee5",
      measurementId: "G-5P0CM7YM8M",
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);
    makeObservable(this, {
      clearPopup: action,
      currentPopup: computed,
      recordingBooth: observable,
      showPopup: action,
      viewPopupId: observable,
    });
  }

  get currentPopup() {
    return this.viewPopupId;
  }

  clearPopup = () => {
    this.viewPopupId = undefined;
  };

  showPopup = (popupId?: PopupOption) => {
    this.viewPopupId = popupId;
  };
}
