// Konsole is a nothing but an extension on top of your
// regular console command
// There are so many things to keep track of in this app
// and so many things prone to go wrong I need a visually
// better way to log things on the console so it is easier
// to track down what is going on

// I am starting a little convention with coloring codes
// This is a work in progress file

class AudioLog {
  important = (text: string) => {
    console.log(`%c${text}`, "color: red; font-size: 30px;");
  };

  logSessionRecorder = (...log: any) => {
    console.log(
      "%c☉ Session Recorder",
      "background-color: #fc0303;  color: white; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };
  logMicrophone = (...log: any) => {
    console.log(
      "%c🎙 Microphone",
      "background-color: #fc03b6;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };

  logAudioSource = (...log: any) => {
    console.log(
      "%c♪ Audio source",
      "background-color: #edf500;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };

  logRecorder = (...log: any) => {
    console.log(
      "%c☉ Recorder",
      "background-color: #fc0303;  color: white; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };

  logVizualizer = (...log: any) => {
    console.log(
      "%c📶 Vizualizer",
      "background-color: #46fafa;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };

  recordingStart = () => {
    console.log(
      "%c▶ Recording",
      "background-color: #fc0303;  color: white; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
    );
  };

  recordingStop = () => {
    console.log(
      "%c☉ Stopped Recording",
      "background-color: #fc0303;  color: white; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
    );
  };

  onSpeechGrammarMatch = (
    perfect: boolean,
    id: any,
    sentence: any,
  ) => {
    if (perfect) {
      console.log(
        `%cPerfect match - matching line ${id}`,
        "background-color: #03fcbe;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
        sentence,
      );
    } else {
      console.log(
        `%Closest match - matching line ${id}`,
        "background-color: #03fcbe;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
        sentence,
      );
    }
  };

  onResult = (...log: any) => {
    console.log(
      `%cSpeech detected`,
      "background-color: #03fcbe;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      ...log,
    );
  };

  onSpeechEnd = () => {
    console.log(
      "%cSpeech Detection ended",
      "background-color: #03fcbe;  color: black; font-weight: bold; border-radius: 4px; padding-left: 4px; padding-right: 4px",
    );
  };

  onSendToRemote = (value?: any) => {
    console.log(
      "%c⚭ Send",
      "background-color: #360568; border-radius: 4px; padding-left: 4px; padding-right: 4px",
      value,
    );
  };
}

export const audioLog = new AudioLog();

/**
 * Method to log every action on a method call
 * @param className class to be decorated
 */
export const logger = (className) => {
  return new Proxy(new className(), {
    get: function (target, name, receiver) {
      if (!target.hasOwnProperty(name)) {
        if (typeof target[name] === "function") {
          console.log(
            "Calling Method : ",
            name,
            "|| on : ",
            target.constructor.name,
          );
        }
        return new Proxy(target[name], this);
      }
      return Reflect.get(target, name, receiver);
    },
  });
};
