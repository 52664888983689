export const colors = {
  dark: "#131313",
  background: "#242424", // dark grey / black
  secondary: "#337BF6",
  surface: "#3A3A3A", // grey
};

export const buttonStyles = {
  backgroundColor: colors.secondary,
  color: "white",
  fontWeight: "bold",
  borderRadius: "4px",
  border: 0,
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingTop: "6px",
  paddingBottom: "6px",
  display: "flex",
  maxWidth: "200px",
};

export const scriptLineStyle = {
  fontFamily: "courier",
  fontSize: "14px",
  color: "white",
};
